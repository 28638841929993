<template>
  <div>
    <vx-card>
      <table class="w-full table-responsive .font-small-2" id="tab" >
          <thead>
            
            <tr>
              <td></td>
              <td @click="go(i)"  v-for="(i, index) in list_date" :key="index" align="center" style="color:#344AD3;" class="but" >
                  <!-- <vs-button  type="flat" v-on:click="go(i)" icon-pack="feather" align="center"> -->
                  <p>{{ i.jour}}</p>
                  
                  <p>{{ i.date }}</p>
                   <!-- </vs-button> -->
              </td>
            </tr>
          </thead>
                <tbody>
                    <tr v-for="hour in list_hour" :key="hour">
                      <td align="center">
                         {{ hour }}
                      </td>
                      <td v-for="i in (0,7)" :key="i" :style="getColor(hour,i)">                          

                      </td>
                    </tr>

                </tbody>
      </table>
      <br>
      <br>
      <br>
      <div>
        <table>
          <tbody>
            <tr>
              <td></td>
              <td id="zero"> <p id="hide">ok</p> </td>
              <td id="un"> <p id="hide">ok</p> </td>
              <td id="quatre"> <p id="hide">ok</p> </td>
              <td id="deux"> <p id="hide">ok</p> </td>
              <td id="trois"> <p id="hide">ok</p> </td>
              
              <td></td>
              
            </tr>
            <tr align="center">
              <td></td>
              <td > 0 </td>
              <td> 1 </td>
              <td> 1 - 3 </td>
              <td> 4 - 7 </td>
              <td> + 7 </td>
            
            </tr>
          </tbody>
        </table>
      </div>
    </vx-card>
  </div>
</template>

<script>
import VxCard from '@/components/vx-card/VxCard.vue'
import moment from 'moment'
import autoRefresh from '@/assets/utils/autoRefresh'
import Utils from '@/assets/utils/utils';

export default {
    name:'WeeklyPlanner',
     components:{
       VxCard,
       Utils
     },
     data(){
       return{
          list_machine:[],
          list_res:[],
          begin:0,
          end:0,
          section:50,
          list_hour:[],
          list_res_duree:[],
          h_max :0,
          list_date:[],
          colors:["#FAFAFA","green","orange","red","black"],
          listCalc:[],


       }

     },
     watch:{
      list_res:function(){
       

      }
     },
     mounted:function(){
      
        autoRefresh.refresh();
     },
     methods:{
       
       update(machine,res,begin,end){
//console.log(" res " , res, " machine : ", machine)
        this.list_machine=machine;
        this.list_res=res;
        this.begin=begin;
        this.end=end;
        
        this.list_date=[]

        
        const c = moment(begin,"LLLL").format("DD/MM"); 
        let v ={
                    jour:Utils.StrCapital(moment(c,"DD/MM/YYYY" ).locale("fr").format("ddd")),
                    date: c
                    
                  }
                
        this.list_date.push(v)
        for (let i=1; i<7; i++){
          let aux1 = moment(c,"DD/MM" ).add(i,'days').format("DD/MM")
          let aux2 = moment(aux1,"DD/MM" ).locale("fr").format("ddd")
          let aux ={
            jour : Utils.StrCapital(aux2),
            date: aux1
          }
          this.list_date.push(aux)

          
        }
        
        this.getSection()
        
       },

       getSection(){
         this.list_res_duree=[]
         
         if( this.list_res.length!=0  ){
          
          let hour = 400;
          let minutes = 400; 

          let mhour = 0;
          let mminutes = 0; 

          let inside = false; 
          
    
          for (let x in this.list_res){
            inside = true;
            
            for(let y in this.list_machine){
              if(this.list_machine[y].uid==this.list_res[x].machine){
                
                
                  let time = this.list_machine[y].duree.split(":");
                  // console.log(" time ; ", time)
                  var endy;
                  const w = moment(this.list_res[x].date, "").format("HH:mm");
                  if ( parseInt(time[0])==0){
                    
                     
                      endy =  moment(w,"HH:mm").add(parseInt(time[1]),"minutes").format("HH:mm")
                  }
                  else{
                    if ( parseInt(time[1])==0){
                      endy =  moment(w,"HH:mm").add(parseInt(time[0]),"hour").format("HH:mm")
                    }
                    else{
                      endy =  moment(w,"HH:mm").add(parseInt(time[0]),"hour").format("HH:mm")
                      endy =  moment(endy,"HH:mm").add(parseInt(time[1]),"minutes").format("HH:mm")
                    }
                  }
                  
                  let v ={
                    dure:this.list_machine[y].duree,
                    debut: moment(this.list_res[x].date, "").format("HH:mm"),
                    fin:endy,
                  }
                  // console.log( " res ", v)
                
                  this.list_res_duree.push(v)
                  
                  if(parseInt(time[0])<hour){
                    hour = parseInt(time[0]);
                  }
                  else{
                    if(mhour<parseInt(time[0]))
                      mhour=parseInt(time[0]);
                    
                  }
                  if(parseInt(time[1])<minutes ){
                    if ( hour ==0 && parseInt(time[1])!=0){
                        minutes= parseInt(time[1]);
                    }
                    else if( hour !=0){
                       minutes= parseInt(time[1])
                       if(parseInt(time[0])==mhour && minutes >mminutes)
                        mminutes= parseInt(time[1])
                    }
                   
                  }
                break;
              }
            }
            
          }
         if(mminutes == 0 && mhour==0){
           if(inside==true){
              mminutes=minutes;
              mhour=hour
           }
           
         }
         const haux =new  moment(mhour,"H").format("HH");
         const maux = new moment(mminutes,"m").format("mm")
         this.h_max=haux+":"+maux;
        
         if(hour ==400 && minutes==400){
            hour=1;
            minutes=1;
          }
          var sec =0; 
          if(hour!=0  ){
              sec = 12/hour;
            
          }
          else{
            
            sec = 12*(60/minutes);
            
          }
          const current = new moment("8","hh");
          let timeList =[]
          if(hour==0){  
            for(let i=0;i<=sec;i++){
                timeList.push(current.format("HH:mm"));
                current.add(minutes, "minutes");
            }
          }
          else{
            for(let i=0;i<=sec;i++){
                timeList.push(current.format("HH:mm"));
                current.add(hour, "hour");
            }
          }
            this.list_hour=timeList;
            this.reset();
            
         }
         

       },

      reset(){
        for ( let com =0; com<this.list_hour.length; com++){
            this.listCalc[com]=[0,0,0,0,0,0,0]
          }
      },

     

      getColor(hour,i){

          // console.log(" i ", i)

          let n = this.list_hour.indexOf(hour);
          // console.log(" n : ", n, " h ", hour)
          for (let j in this.list_res){
            
            
            let h = this.list_res[j].date.getHours().toString();
            let m = this.list_res[j].date.getMinutes().toString();
            if (m=="0"){
              m=m+"0"
            }
            if( h.length==1){
              h="0"+h;
            }
            // console.log(" h : ", h, " m : ", m)
            let hh = hour.split(":")
            // let hi = parseInt(hh[0])
            // let mi = parseInt(hh[1])

           


            //  let heure = h+":"+m;
            
            let deb=this.list_res_duree[j].debut.split(":");
            
            // let debh=parseInt(deb[0]);
            // let debm=parseInt(deb[1]);

            let endy=this.list_res_duree[j].fin.split(":");
            // let endh=parseInt(endy[0]);
            // let endm=parseInt(endy[1]);
            
            
            let debuth = deb[0]+deb[1]
            let finh = endy[0]+endy[1]
            let hourh = hh[0]+hh[1]

            
            
            
            switch ( this.list_res[j].date.getDay()){
              case 0: // dimanche
                if( i==7 ){
                    
                    if ( hourh >= debuth && hourh<finh){
                      
                      this.listCalc[n][i-1]++;
                    }


                  
                }
                break;
              case 1: // lundi
                if( i==1 ){

                  if ( hourh >= debuth && hourh<finh){
                    
                    this.listCalc[n][i-1]++;
                  }
                  
                 
                    
                  
                 
                }
                break;
              case 2: // mardi 
                if( i==2 ){

                  // console.log(" ?? ",this.list_res[j] )
                  if ( hourh >= debuth && hourh<finh){
                    this.listCalc[n][i-1]++;
                  }

               
                }
                break;
              case 3:   // mercredi 
                if( i==3 ){

                  if ( hourh >= debuth && hourh<finh){
                    this.listCalc[n][i-1]++;
                  }
                 
               

                }
                break;
              case 4:   // jeudi 
                if( i==4 ){

                  if ( hourh >= debuth && hourh<finh){
                   
                    this.listCalc[n][i-1]++;
                  }
                  
                 
                  
                  
                }
                break;
              case 5: // vendredi
                if( i==5 ){
                  
                  
                  
                  if ( hourh >= debuth && hourh<finh){
                    
                    this.listCalc[n][i-1]++;
                  }
                  
                  
                  
                }
                break;
              case 6:   // samedi 
                if( i==6 ){

                  if ( hourh >= debuth && hourh<finh){
                    
                    this.listCalc[n][i-1]++;
                  }

                 
                   
                }
                break;
            }
          }
          // console.log(" this ", this.listCalc)
          if( this.listCalc[n][i-1]==0)
            return "background:white";
          else if (this.listCalc[n][i-1]==1)
            return "background:#baffc9";
          else if (this.listCalc[n][i-1]>1 && this.listCalc[n][i-1] <=3)
            return "background:#FFEFD5";
          else if (this.listCalc[n][i-1]>=4 && this.listCalc[n][i-1] <7)
            return "background:#ffdfba";
          else if (this.listCalc[n][i-1] >=7)
            return "background:#ffb3ba";
          else
            return "background:black";

          
      },
      go(i){  // permet de changer de page 
        const c = moment(i.date,"DD/MM/YYYY").format("MM-DD-YYYY"); 
        
        if( c != this.$route.params.date )
                this.$router.push('/admin/booking/'+c);
      }
     }
}
</script>

<style>
#zero{
  background-color: white;
  
}
#un{
  background-color: #baffc9;
  
}
#deux{
  background-color: #ffdfba;
  
}
#trois{
  background-color: #ffb3ba;
  
}
#quatre{
  background-color: #FFEFD5;
  
}

#hide{
  visibility: hidden;
}

#tab{
  border-collapse: collapse;
  
  overflow-y: hidden;
  
}

.but:hover{
  background: #D1C4E9;
}
</style>