<template>
   <div>
       <div class="page_booking">
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
            <div>
                <vs-button color="primary" type="border" icon-pack="feather" icon="icon-chevrons-left" class="dateBtn" v-on:click="addDay(-7)"></vs-button>
            </div>
            <div class="text-center cursor click selector_date">
                <span v-on:click="toDay()">Aujourd'hui </span><br/>
                <cDate v-model="date" ref="cDate" />
            </div>
            <div>
                <vs-button color="primary" type="border" icon-pack="feather" icon="icon-chevrons-right" class="dateBtn" v-on:click="addDay(7)"></vs-button>
            </div>
            </vs-col>
        </vs-row>
        <br>
        <vs-row>
            <weeklyPlanner ref="plan"></weeklyPlanner>
        </vs-row>
       </div>
      
   </div>
</template>

<script>
import moment from 'moment'
import BookingMatriciel from '@/components/booking/matriciel.vue'
//import BookingPanel from '@/components/booking/panel.vue'
import MembrePopup from '@/components/booking/membre.vue'
import SelectAddPopup from '@/components/booking/selectAdd.vue'
import AddPopup from '@/components/booking/add.vue'
import AddMember from '@/components/member/add.vue'
import cDate from '@/components/app/date'
import Credit from '@/assets/utils/credit'
import WeeklyPlanner from '@/components/booking/weeklyPlanner.vue'

import Utils from '@/assets/utils/utils';
import Fdate from '@/assets/utils/fdate';


import Member  from '@/database/models/member';
import Booking  from '@/database/models/booking';
import Machine  from '@/database/models/machine';
import BookingHour  from '@/database/models/booking_hour';

// import moment from 'moment'

import autoRefresh from '@/assets/utils/autoRefresh'

export default {
    components:{
            BookingMatriciel,
            MembrePopup,
            SelectAddPopup,
            AddPopup,
            AddMember,
            cDate,
            Utils,
            Fdate,
            Member,
            autoRefresh,
            Credit,
            Booking, 
            WeeklyPlanner,
            Machine,
            BookingHour,
            
        },
        data(){
            return{
                date: new Date(),
                list_res:{},
                list_semaine:[],
                list_machine:[],
                deb:0,
                endd:0,

            }
        },
        watch:{
        $route (){
            if(this.$route.params.date != undefined)
                this.date = new Date( this.$route.params.date.replace(/-/g, "/") );
            autoRefresh.refresh();
            
        },
        date:function(){
            this.list_semaine={}
            let d = (this.date.getMonth()+1)+'-'+this.date.getDate()+'-'+this.date.getFullYear();
            if( d != this.$route.params.date )
                this.$router.push('/admin/weekly/'+d);
            
            autoRefresh.SetCallback(async()=>
            {
                await this.getAllMem(this.date);
                this.$refs.plan.update(this.list_machine, this.list_semaine, this.deb, this.endd)
            
            })
            autoRefresh.refresh();
            
            
        }
    },
    mounted:function()
	{
        if(this.$route.params.date!= moment(this.date,"").format("MM-DD-YYYY")){
            let v = this.$route.params.date 
            //console.log(" v : ", v)
            let x = moment(v,"MM-DD-YYYY").toDate();
            this.date=x;
        }
        
        //autoRefresh
        autoRefresh.SetCallback(async()=>
        {
            await this.getAllMem(this.date);
            this.$refs.plan.update(this.list_machine, this.list_semaine, this.deb, this.endd)
            
           
        })
        autoRefresh.refresh();
    },
    methods:{
       
        async getAllMem(dateselected){
            
           let j=0;
           // je verifie que la reservation n'est pas pour un cours 
            
            this.list_res = await Booking.AwaitGetAllMemory();
            let machinee = await Machine.AwaitGetAllMemory();
            for (var i in this.list_res){
                
                let tab = this.list_res[i];
                
                    // je recupere le jour correspondant 
                let jour = dateselected.getDay();
                // let day = dateselected.getDate();
                let debut = jour;
                let fin = 7-jour;
                
                // je calcule le debut de la semaine et la fin 
                let begin = new Date( dateselected )
                
            
                let end = new Date( dateselected )
                if(jour==0){ // si dimanche 
                    
                    begin.setDate(begin.getDate() - 6);
                
                }
                else{
                    begin.setDate(begin.getDate() - debut +1)
                    end.setDate(end.getDate() + fin)
                }
                
                

                // je recupere les données du debut et fin de semaine 
                let beginDay = begin.getDate();
                let beginMonth= begin.getMonth();
                let beginYear= begin.getFullYear();

                let endDay = end.getDate();
                let endMonth= end.getMonth();
                let endYear= end.getFullYear();
                
                this.deb=begin;
                this.endd=endDay;
                
            
                //console.log(" y ",tab.date.getFullYear(), " m ",tab.date.getMonth(), " dd ", tab.date.getDate(), " tab : ", tab )
                
                
                if( machinee[tab.machine].type!="g"){
                    if (tab.date.getFullYear()>=beginYear && tab.date.getFullYear()<=endYear){
                        if (tab.date.getMonth()>=beginMonth && tab.date.getMonth()<=endMonth){
                            //console.log(" month ", endMonth)
                            //console.log(" taDay ",tab.date.getDate() , " begin", beginDay, "tab : ", tab)
                            if( tab.date.getDate()>=beginDay && tab.date.getDate()<=endDay){
                                if( tab.type!="b"){
                                    this.list_semaine[j]=tab;
                                    j++;
                                }
                                
                            }
                            else if (tab.date.getDate()>=beginDay && tab.date.getDate()>endDay && tab.date.getMonth()+1==endMonth ){
                                if( tab.type!="b"){
                                    this.list_semaine[j]=tab;
                                    j++;
                                }
                            }
                        }
                    }
                }
        
        
        
            }
            
            // séparer en jour 
            var lundi=[];
            var mardi=[];
            var mercredi=[];
            var jeudi=[];
            var vendredi=[];
            var samedi=[];
            var dimanche=[];
            var sem=this.list_semaine;

            for (var k in this.list_semaine){

                let day = this.list_semaine[k].date.getDay();
                switch(day){
                    case 1:
                        lundi.push(sem[k]);
                        break;
                    case 2:
                        mardi.push(sem[k]);
                        break;
                    case 3:
                        mercredi.push(sem[k]);
                        break; 
                    case 4:
                        jeudi.push(sem[k]);
                        break;
                    case 5:
                        vendredi.push(sem[k]);
                        break;
                    case 6:
                        samedi.push(sem[k]);
                        break;
                    case 7:
                        dimanche.push(sem[k]);
                        break;
                }
                
            }
            var week=[];
            week.push(lundi, mardi, mercredi, jeudi, vendredi,samedi,dimanche)
           
            // diviser en crénaux horaires 

            let machine=[];
            // let duree =[];
            let listMachine = await Machine.AwaitGetAllMemory();
            //console.log(" machine : ", listMachine)
            let hours  = await BookingHour.AwaitGetAllMemory();
           // console.log(" booking hour  : ", hours)

            for(let x in listMachine){
                if(listMachine[x].type!="g"){
                    let duration = this.getDuree(hours, x);
                
                    let mach ={
                        uid:x,
                        name:listMachine[x].name,
                        duree:duration
                    }
                    machine.push(mach)
                }
                
            }
            this.list_machine=machine;
            
        }, 
        getWeek(){
            //console.log(" semaine : ", this.list_semaine)
        },
        toDay()
        {
            let d = new Date()
            
            let fd = ( d.getMonth()+1)+'-'+d.getDate()+'-'+d.getFullYear();
            if( fd != this.$route.params.date )
                this.$router.push('/admin/weekly/'+fd).catch(()=>{})     
        },
        addDay( nb )
        {
            let d = new Date( this.date )
            d.setDate(d.getDate() + nb)
            
            let fd = ( d.getMonth()+1)+'-'+d.getDate()+'-'+d.getFullYear();
            if( fd != this.$route.params.date )
                this.$router.push('/admin/weekly/'+fd).catch(()=>{})
            
        },
        getDuree(list, machine){
            for (let i in list){
                if(list[i].machine==machine){
                    
                    return list[i].duration
                }
            }
            return "00:30" ;
        },
        getMember( uid ){
            var mbr = this.list_member[uid]
            if(!mbr)
                return 'Membre archivé.'

            return Utils.StrCapital(mbr.first_name+" "+mbr.last_name)
        },
        
    }
}
</script>


<style lang="scss" scoped>

.header-sidebar 
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    h4 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        > button {
            margin-left: 10px;
        }
    }
}
.header-body{
    margin:20px;
}

.footer-sidebar
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    > button {
        border: 0px solid rgba(0, 0, 0, 0) !important;
        border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
        border-radius: 0px !important;
    }
}
.btn_resa .vs-button{
    margin-bottom:10px;
}
.dateBtn{
    display:inline !important;
    margin:5px;
}
.vdp-datepicker input{
    text-align:center;
}




</style>

